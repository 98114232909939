import * as React from "react";
import { PageProps, graphql } from "gatsby";

import Layout from "../components/layout";
import { useTranslation } from "react-i18next";
import { PageHeaderWithBreadcrumbs } from "../components/pageHeaderWithBreadcrumbs";
import { TranslatedLink } from "gatsby-plugin-translate-urls";
import { CommonFunctions } from "../components/pageFragments/functions";

type DataProps = {
  site: {
    buildTime: string;
  };
};

const Functions: React.FC<PageProps<DataProps>> = ({ location }) => {
  const { t } = useTranslation();

  return (
    <Layout
      seoTitle={t("functions.title")}
      seoDescription={`${t("functions.headlinePart1")} ${t(
        "functions.headlinePart2"
      )}`}
      location={location}
    >
      <div className="container">
        <PageHeaderWithBreadcrumbs
          currentBreadcrumbText={t("functions.title")}
          title={t("functions.pageTitle")}
        />
        <div className="row">
          <div className="col-md-6 mb-4 mb-md-0 pr-lg-0">
            <h2 className="about-section-one-title">
              {t("functions.headlinePart1")}{" "}
              <span className="font-weight-normal">
                {t("functions.headlinePart2")}
              </span>
            </h2>
            <div className="about-section-one-content">
              <p>{t("functions.underHeadline")}</p>
            </div>
          </div>
          <div className="col-md-6 pl-lg-0 d-flex align-items-center align-items-lg-end">
            <img
              src="/images/functions-main-image.png"
              alt="about"
              className="img-fluid"
              width="448px"
            />
          </div>
        </div>
        <div className="pt-5">
          <CommonFunctions />
        </div>
        <div className="row pb-5 pt-5">
          <div className="col-lg-6 mb-4">
            <div className="card pricing-card border-primary active">
              <div className="card-body">
                <h3 className="mb-0">
                  {t("functions.goodForSection.goodForText")}
                </h3>
                <h3 className="text-primary mb-3">
                  {t("functions.goodForSection.manager")}
                </h3>
                <p className="mb-4">
                  {t("functions.goodForSection.managerDescription")}
                </p>
                <TranslatedLink
                  to={"/contact"}
                  className="btn btn-primary btn-rounded"
                >
                  {t("functions.goodForSection.contactUsButton")}
                </TranslatedLink>
              </div>
            </div>
          </div>
          <div className="col-lg-6 mb-4">
            <div className="card pricing-card border-success active">
              <div className="card-body">
                <h3 className="mb-0">
                  {t("functions.goodForSection.goodForText")}
                </h3>
                <h3 className="text-success mb-3">
                  {t("functions.goodForSection.driver")}
                </h3>
                <p className="mb-4">
                  {t("functions.goodForSection.driverDescription")}
                </p>
                <TranslatedLink
                  to={"/contact"}
                  className="btn btn-outline-success btn-rounded"
                >
                  {t("functions.goodForSection.contactUsButton")}
                </TranslatedLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Functions;

export const query = graphql`
  query ($language: String!) {
    site {
      buildTime(formatString: "YYYY-MM-DD hh:mm a z")
    }
    locales: allLocale(
      filter: { ns: { in: ["index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
